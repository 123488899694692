<template>
  <div class="mLogin">
    <!-- 登录页标题 -->
    <div class="mLogin_title">账号登录</div>
    <div class="mLogin_box">
      <div class="mLogin_phone">
        <input type="text" v-model="phone" placeholder="请输入账号" />
      </div>
      <div class="mLogin_password">
        <input type="password" v-model="password" placeholder="请输入密码" />
      </div>
      <div class="mLogin_btn">
        <button @click="loginIn()">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
    };
  },
  created() {
    let codeArr = document.location.search.split("=");
    if (codeArr.length == 2) {
      this.userCode = codeArr[1];
      if (!localStorage.getItem("user_token")) {
        this.getToken();
      }
    }
  },
  methods: {
    loginIn() {
      // 账号、密码登录
      if (this.phone == "" || this.password == "") {
        this.$message.error("账号或密码都不能为空");
      } else {
        if (this.keepPassword) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              phone: this.phone,
              password: this.password,
            })
          );
          localStorage.setItem("user_status", this.keepPassword);
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("user_status");
        }
        this.$service
          .DEFAULT({
            url: "https://usercen.centurysouth.cn/user/login",
            method: "POST",
            data: {
              app: "oa",
              phone: this.phone,
              password: this.password,
            },
          })
          .then((res) => {
            if (res.code && res.code == 200) {
              localStorage.setItem("user_token", res.data.token); // 缓存token
              this.$router.push({ path: "/mHome" });
            } else {
              alert(res.msg);
            }
          });
      }
    },
    getToken() {
      this.fullscreenLoading = true;
      this.$axios({
        url: "https://ppt.centurysouth.cn/tmp/get/token",
        // url: "https://oav1.centurysouth.cn/auth/token/code/get",
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8;"
        },
        params: {
          code: this.userCode
        }
      })
        .then(res => {
          this.fullscreenLoading = false;
          debugger;
          if (res.data.code && res.data.code == 200) {
            localStorage.setItem("user_token", res.data.data); // 缓存token
            this.$router.push({ path: "/" });
          }
        })
        .catch(e => {
          this.fullscreenLoading = false;
          console.log(e);
        });
    }
  },
};
</script>

<style scoped>
.mLogin {
  background-color: #fff;
}
.mLogin_title {
  color: #000;
  padding: 3% 0;
  text-align: center;
  background-color: #fdd30f;
}
.mLogin_box {
  padding: 6rem 3rem;
}
.mLogin_box input {
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
}
.mLogin_phone {
  margin-top: 5%;
}
.mLogin_password {
  margin-top: 5%;
}
.mLogin_btn {
  margin-top: 10%;
}
.mLogin_btn > button {
  border: none;
  outline: none;
  color: #000;
  width: 100%;
  padding: 3%;
  border-radius: 5px;
  background-color: #fdd30f;
}
</style>